<template>
    <div>
        <v-dialog v-model="imgdialog" max-width="450px">
            <v-card tile>
                <v-card-text>
                    <v-container>
                        <v-img
                                contain
                                max-height="150"
                                :src="selectedSrc"
                        ></v-img>
                    </v-container>
                </v-card-text>

                <v-divider class="primary"/>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="() => {imgdialog=false}">
                        Back
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="450px" persistent>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                    Create
                </v-btn>
            </template>
            <v-card tile>
                <v-card-title class="primary white--text">
                    <span class="text-h5"><v-icon large color="white" class="mr-2">{{formIcon}}</v-icon>{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row no-gutters>
                            <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedImage">
                                <v-img
                                        contain
                                        max-height="150"
                                        :src="selectedImage"
                                ></v-img>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input
                                        label="Cover"
                                        outlined
                                        dense
                                        @change="getImage"
                                        v-model="editedItem.img_url"
                                        prepend-icon=""
                                        append-icon="mdi-image"
                                ></v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        outlined
                                        dense
                                        v-model="editedItem.name"
                                        :error-messages="nameErrors"
                                        required
                                        @input="editedIndex == -1 ? $v.editedItem.name.$touch(): null"
                                        @blur="editedIndex == -1 ? $v.editedItem.name.$touch(): null"
                                        label="Name"
                                        append-icon="mdi-rename-box"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        outlined
                                        dense
                                        type="date"
                                        v-model="editedItem.banner_ads_date"
                                        :error-messages="bannerAdsDateErrors"
                                        required
                                        @input="editedIndex == -1 ? $v.editedItem.banner_ads_date.$touch(): null"
                                        @blur="editedIndex == -1 ? $v.editedItem.banner_ads_date.$touch(): null"
                                        label="Date"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-alert v-if="error" dense type="error">
                                    {{error}}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-divider class="primary"/>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="success" @click="save">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="error white--text"
                >
                    <v-icon color="white" class="mr-2">mdi-delete</v-icon>
                    You sure you want to delete this item?
                </v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="closeDelete"
                    >Cancel
                    </v-btn
                    >
                    <v-btn color="success" @click="deleteItemConfirm"
                    >OK
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table
                :headers="headers"
                :items="data"
                sort-by="calories"
                class="elevation-3"
        >
            <template v-slot:item.img_url="{item}">
                <v-avatar class="profile my-2" color="grey" size="80" tile @click="openImage(item.img_url)">
                    <v-img contain v-if="item.img_url" :src="item.img_url"></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small @click="deleteItem(item)" class="error--text">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:item.banner_ads_date="{ item }">
                <span> {{ item.banner_ads_date !== null && item.banner_ads_date !== undefined ? moment(`${item.banner_ads_date}`).format('Do MMM YY') : '' }}</span>
            </template>
            <template v-slot:no-data>
                <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="secondary"
                ></v-progress-circular>
                <v-icon
                        v-else
                        x-large
                        color="grey lighten-1"
                >
                    mdi-tray-remove
                </v-icon>

            </template>
        </v-data-table>
    </div>
</template>
<script>
    import {required} from 'vuelidate/lib/validators'
    import {validationMixin} from 'vuelidate'
    import moment from "moment";

    export default {
        name: 'Banners',
        mixins: [validationMixin],
        data: () => ({
            imgdialog: false,
            selectedSrc: null,
            headers: [
                {
                    text: 'Cover',
                    value: 'img_url',
                    sortable: false,
                    align: 'start'
                },
                {text: 'Name', value: 'name'},
                {text: 'Date', value: 'banner_ads_date'},
                {text: 'Actions', value: 'actions', sortable: false},
            ],
            data: [],
            apiUrl: process.env.VUE_APP_API_URL,
            loading: true,
            dialog: false,
            dialogDelete: false,
            error: '',
            editedIndex: -1,
            editedItem: {
                name: '',
                img_url: null,
                banner_ads_date: ''
            },
            defaultItem: {
                name: '',
                img_url: null,
            },
            selectedImage: null,
        }),
        created() {
            this.getBanners();
        },
        methods: {
            moment: function (b) {
                return moment(b);
            },
            openImage(src) {
                this.selectedSrc = src
                this.imgdialog = true
            },
            editItem(item) {
                this.editedIndex = this.data.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.editedItem.img_url = null
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.data.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                this.deleteBanner(this.editedItem.id)
                this.closeDelete();
            },
            close() {
                this.dialog = false;
                this.$v.$reset()
                this.error = ''
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            save() {
                this.editedIndex == -1 ? this.addBanner() : this.updateBanner()
            },
            //Banners API Calls
            getImage() {
                let formData = new FormData();
                formData.append('img_url', this.editedItem.img_url);
                this.$Axios({
                    method: 'post',
                    url: 'add-banner-image',
                    data: formData,
                    headers: {'Content-Type': 'multipart/form-data'},
                })
                    .then(({data}) => {
                        this.selectedImage = data.bannerImageUrl
                    })
                    .catch((e) => {
                        console.log(e)
                    });
            },
            getBanners() {
                this.loading = true
                this.$Axios
                    .get('get-banner-ads')
                    .then((res) => {
                        if(res.data['success']){
                            this.data = res.data['data'];
                        }else{
                            alert(res.data['msg']);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            addBanner() {

                this.error = ''
                this.$v.$touch()
                console.log(this.$v)
                if (this.$v.$error) {
                    return
                }
                let data = {}
                data.name = this.editedItem.name
                data.banner_ads_date = this.editedItem.banner_ads_date
                data.img_url = this.selectedImage

                this.$Axios
                    .post('add-banner-ads', data)
                    .then(() => {
                        this.getBanners();
                        this.close();
                    })
                    .catch((e) => {
                        console.log(e)
                    });
            },
            updateBanner() {
                this.error = ''
                this.$v.$touch()

                if (this.$v.$error) {
                    return
                }
                let formData = new FormData();
                if (this.editedItem.name) formData.append('name', this.editedItem.name);
                if (this.editedItem.img_url) formData.append('img_url', this.editedItem.img_url);
                if (this.editedItem.banner_ads_date) formData.append('banner_ads_date', this.editedItem.banner_ads_date);
                if (this.editedItem.font_colour) formData.append('font_colour', this.editedItem.font_colour);
                if (this.editedItem.political_party) formData.append('political_party', this.editedItem.political_party)
                if (this.editedItem.type) formData.append('type', this.editedItem.type);
                console.log('adding item : ', formData);
                this.$Axios({
                    method: 'post',
                    url: 'update-banner-ads?id=' + this.editedItem.id,
                    data: formData,
                    headers: {'Content-Type': 'multipart/form-data'},
                })
                    .then(() => {
                        this.getBanners();
                        this.close();
                    })
                    .catch((e) => {
                        console.log(e)
                    });
            },
            deleteBanner(id) {
                this.$Axios
                    .post('delete-banner-ads?id=' + id)
                    .then(() => {
                        this.getBanners();
                    })
                    .catch((e) => {
                        console.log(e);
                    })
            },
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'New Banner' : 'Edit Banner';
            },
            formIcon() {
                return this.editedIndex === -1 ? 'mdi-plus' : 'mdi-edit';
            },
            filtredItems() {
                if (this.filter) return this.desserts.filter(item => item.name.includes(this.filter) || item.license.includes(this.filter) || item.email.includes(this.filter) || item.db.includes(this.filter))
                else return this.desserts
            },
            //// Vuelidate Errors
            nameErrors() {
                const errors = []
                if (!this.$v.editedItem.name.$dirty) return errors
                !this.$v.editedItem.name.required && errors.push('Name is required !')
                return errors
            },
            bannerAdsDateErrors() {
                const errors = []
                if (!this.$v.editedItem.banner_ads_date.$dirty) return errors
                !this.$v.editedItem.banner_ads_date.required && errors.push('Banner Date is required !')
                !this.$v.editedItem.banner_ads_date.date && errors.push('Banner Date must be YYYY-MM-DD')
                return errors
            },
            img_urlErrors() {
                const errors = []
                if (!this.$v.editedItem.img_url.$dirty) return errors
                !this.$v.editedItem.img_url.required && errors.push('Cover is required !')
                return errors
            },
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        validations: {
            editedItem: {
                name: {
                    required
                },
                img_url: {
                    required,
                },
                banner_ads_date: {
                    required,
                    date: (value) => { return moment(value, 'YYYY-MM-DD', true).isValid()}
                }
            },
        },
    };
</script>